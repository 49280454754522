 import io from "socket.io-client";
// export const socket = io('ws://localhost:3008',{
//     auth:{
//         sessionId: localStorage.getItem('sessionId') 
//     }
// });

// export const socket = io('ws://192.168.1.2:3008',{
//     auth:{
//         sessionId: localStorage.getItem('sessionId') 
//     }
// });

export const socket = io('https://dogs8.corriendogs.com',{
    auth:{
        sessionId: localStorage.getItem('sessionId') 
    }
 });

 // db.jackpots.update({point:ObjectId("636c5e19fff7eadd671e7e50")},{$set:{amountToPay:4500}})
// db.jackpots.update({_id: ObjectId("636a58dc0a7910f7e2026067")},{$set:{amountToPay:16200}})